import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../assets/css/global.css';
import Header from '../header/header';
import ArtWorkList from './artWorkList';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAlbum } from '../../components/privateRoute/albumContext';
import Footer from '../footer/footer';
import ProgressBar from '../../components/progressbar/progressbar';

const ArtTab = () => {
  const { id } = useParams();
  const { albumDetails } = useAlbum();
  const { artworkStatus } = useAlbum();
  const [downloadProgress, setDownloadProgress] = useState(0);
  const title = sessionStorage.getItem('title');
  const status = sessionStorage.getItem('status');
  const [isAudioPopupOpen, setAudioPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [trackCount, setTrackCount] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const openAudioPopup = () => {
    setAudioPopupOpen(true);
  };

  const closeAudioPopup = () => {
    setAudioPopupOpen(false);
  };

  useEffect(() => {
    AOS.init();
    document.body.classList.remove('home-bg');

  }, []);

  const [activeTab, setActiveTab] = useState('audio');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName + '/' + id);
  };

  const handleArtClick = () => {
    navigate('/audio/' + id);
  };

  const handleArtForm = () => {
    navigate('/form-for-artwork/' + id);
  };

  const showProgressBar = () => {
    setShowProgress(true);
  };

  const hideProgressBar = () => {
    setShowProgress(false);
  };

  
  const tracks = [
    { id: 1, title: 'The Particles - Inhumanity', audioSrc: '1.mp3', format: 'mp3' },
    { id: 2, title: 'The Particles - Bitter Years', audioSrc: '1.mp3', format: 'mp3' },
    { id: 3, title: 'The Particles - I Believe', audioSrc: '1.mp3', format: 'mp3' },
    { id: 4, title: 'The Particles - Full Circle', audioSrc: '1.mp3', format: 'mp3' },
    { id: 5, title: 'The Particles - Incapable of Love', audioSrc: '1.mp3', format: 'mp3' },
  ];
  const simulateDownloadProgress = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setDownloadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 500); // Adjust the interval and step size as needed
  };

  useEffect(() => {
    // Simulate a download for demonstration
    simulateDownloadProgress();
  }, []);
  return (
    <>
      <Header />
      <div className="space"></div>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alink">
                <Link to={`/albums/`}>
                  Albums
                </Link>
                <span style={{ paddingLeft: "0.7rem" }} className='albumNameLink'>
                  {title}
                </span>
                {showProgress && <ProgressBar progress={downloadProgress} />}

              </div>
              <ul className="tab-view">
                <li className={activeTab === 'artwork' ? 'first active' : 'first'}>
                  <a href="#" onClick={() => handleTabClick('artwork')}>Artwork</a>
                </li>
                <li className={activeTab === 'audio' ? 'second active' : 'second'}>
                  <a href="#" onClick={handleArtClick}>Audio Files</a>
                </li>
                {(artworkStatus === 0 || parseInt(status, 10) === 0 || parseInt(status, 10) === 3 || artworkStatus === 3) && trackCount > 0 && (
                  <li className="last">
                    <a href="#" onClick={handleArtForm} className="add-new-album">Re-upload Artwork</a>
                  </li>
                )}
              </ul>

              {activeTab === 'audio' && (
                <ArtWorkList
                  artworkStatus={artworkStatus}
                  setTrackCount={setTrackCount}
                  showProgressBar={showProgressBar}
                  hideProgressBar={hideProgressBar}
                  setDownloadProgress={setDownloadProgress}
                />
              )}

            </div>
          </div>
        </div>
      </section>
      {/* {isAudioPopupOpen && <UploadAudio closeAudioPopup={closeAudioPopup} />} */}
      
      <Footer />
    </>
  );
};

export default ArtTab;
