import React, { useState } from 'react';
import "react-popupbox/dist/react-popupbox.css";
import '../../assets/css/global.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import CreateIPRAgreement from '../../apis/createIPRAgreement/createIPRAgreement';
import {useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const IPRPopupForm = ({ closeIPRPopup, albumTitle,albumValue }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [region, setRegion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countryError, setCountryError] = useState('');
    const [stateError, setStateError] = useState('');
    const [formData, setFormData] = useState({
        AlbumName: '',
        username: '',
        company: '',
        country: '',
        zip: '',
        streetAddress: '',
        aptSuite: '',
        city: '',
        state: '',
        email: '',
        phone: '',
        album_Agreement: false,
        ipR_Agreement: false
    });
      
    const isFullName = (name) => {
        const nameParts = name.trim().split(' ');
        return nameParts.length >= 2;
    };

    const selectCountry = (val) => {
        setCountry(val);
        setRegion('');
        setFormData(prevFormData => ({
            ...prevFormData,
            country: val,
            state: ''
        }));
        setCountryError('');
    };

    

    const selectRegion = (val) => {
        setRegion(val);
        setFormData(prevFormData => ({
            ...prevFormData,
            state: val
        }));
        setStateError(''); 
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      
        if (name === 'username') {
          const nameParts = value.trim().split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.slice(1).join(' ');
          setFormData(prevFormData => ({
            ...prevFormData,
            firstName: firstName,
            lastName: lastName
          }));
      
          if (!value.trim()) {
            setValidationErrors(prevErrors => ({
              ...prevErrors,
              [name]: 'User name is required'
            }));
          } else if (!isFullName(value)) {
            setValidationErrors(prevErrors => ({
              ...prevErrors,
              [name]: 'Please enter a valid full name (first name and last name)'
            }));
          } else {
            setValidationErrors(prevErrors => ({
              ...prevErrors,
              [name]: undefined
            }));
          }
        } else {
          setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: undefined
          }));
        }

        if (name === 'phone') {
            const phoneRegex = /^\d{10}$/;
            if (!phoneRegex.test(value.trim())) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    phone: 'Invalid phone number',
                }));
            } else {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    phone: undefined,
                }));
            }
        }

        if (type === 'checkbox') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked,
            }));
            
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                [name]: checked ? undefined : 'You must agree to the terms',
            }));
            
            return;
        }
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();
    
        setValidationErrors({});
    
        const errors = {};
    
        // Validate form fields
        if (!formData.username.trim()) {
            errors.username = 'User name is required';
        } else if (!isFullName(formData.username)) {
            errors.username = 'Please enter a valid full name (first name and last name)';
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            errors.email = 'Email address is required';
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
    
        const phoneRegex = /^\d{10}$/;
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone = 'Invalid phone number';
        }
    
        if (!formData.zip.trim()) {
            errors.zip = 'ZIP code is required';
        } else if (!/^[a-zA-Z0-9\s]{1,10}$/.test(formData.zip)) {
            errors.zip = 'The ZIP code is invalid';
        }
        
        
        if (!formData.streetAddress.trim()) {
            errors.streetAddress = 'Street address is required';
        }
    
        if (!formData.city.trim()) {
            errors.city = 'City/Town is required';
        }
    
        if (!formData.ipR_Agreement) {
            errors.ipR_Agreement = 'You must agree to the terms';
        }
    
        if (!country) {
            setCountryError('Please select a country');
        }
        if (!region) {
            setStateError('Please select a state');
        }
        console.log(errors);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setIsLoading(false);
            return;
        }
    
        setIsLoading(true);
    
        try {
            const authenticationToken = sessionStorage.getItem('token');
            const response = await CreateIPRAgreement(authenticationToken, {
                ...formData,
                firstName: formData.firstName,
                lastName: formData.lastName,
                albumId: albumValue,
                album_Agreement: true
               
            });
    
            if (response.result === 1 && response.resultValue) {
                toast.success('IRP form submitted successfully!');
                    setTimeout(() => {
                        closeIPRPopup();
                      }, 2000);
              
                
            } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
                console.error('Invalid authentication token:', response.errorValue.errorText);
                sessionStorage.clear();
                navigate('/');
      
              } else {
                console.error('API call returned an error:', response);
      
              }
        } catch (error) {
            console.error('API call error:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <>
            {isLoading && (
                <div className="loader-container">
                    <div className="loader"></div>

                </div>
            )}
            <div className="popup-fixed"></div>
            <div className="popup-container">
                <div className="login-popup">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 m-auto shadow">
                                <div className="row relative">
                                    <div className="col-md-12"><span className="close-popup" onClick={closeIPRPopup}></span></div>
                                    <div className="col-md-12">
                                        <div className="ipr">
                                            <h2>AUDIO INTELLECTUAL PROPERTY RIGHTS (IPR)</h2>
                                            <p>USER INFORMATION</p>
                                            <form onSubmit={handleSubmit}>
                                                <ul>
                                                    <li><input type="text" className="input" placeholder="Name of the album" onChange={handleInputChange} value={albumTitle} disabled />

                                                    </li>
                                                    <li><input type="text" className="input" name="username" placeholder="User Name" onChange={handleInputChange} />
                                                        {validationErrors.username && <span className="error">{validationErrors.username}</span>}
                                                    </li>
                                                    <li><input type="text" className="input" name="company" placeholder="Company/Label Name(Optional)" onChange={handleInputChange} />
                                                    </li>
                                                    <li><CountryDropdown className="input select" name="country" value={country} onChange={selectCountry} />
                                                        {countryError && <span className="error">{countryError}</span>}
                                                    </li>
                                                    <li><input type="text" className="input" placeholder="Zip Code" name="zip" onChange={handleInputChange} />
                                                        {validationErrors.zip && <span className="error">{validationErrors.zip}</span>}
                                                    </li>
                                                    <li><input type="text" className="input" placeholder="Street Address" name="streetAddress" onChange={handleInputChange} />
                                                        {validationErrors.streetAddress && <span className="error">{validationErrors.streetAddress}</span>}
                                                    </li>
                                                    <li><input type="text" className="input" name="aptSuite" placeholder="Apt, Suite, Bldg. (if applicable)" onChange={handleInputChange} /></li>
                                                    <li><input type="text" className="input" placeholder="City / Town" name="city" onChange={handleInputChange} />
                                                        {validationErrors.city && <span className="error">{validationErrors.city}</span>}
                                                    </li>
                                                    <li> <RegionDropdown className="input select" name="state" value={region} country={country} onChange={selectRegion} />
                                                        {stateError && <span className="error">{stateError}</span>}
                                                    </li>
                                                    <li><span></span></li>
                                                    <li><input type="text" className="input" placeholder="Email Address" name="email" onChange={handleInputChange} />
                                                        {validationErrors.email && <span className="error">{validationErrors.email}</span>}
                                                    </li>
                                                    <li><input type="text" className="input" placeholder="Phone Number" name="phone" onChange={handleInputChange} />
                                                        {validationErrors.phone && <span className="error">{validationErrors.phone}</span>}
                                                    </li>
                                                    <li><span></span></li>
                                                    <li><p>ALBUM INFORMATION<br /> *Please Note: If you are ordering spine Lables, the "Artist/Band Name" and "Album/Project Name" info will be used to create it.</p></li>
                                                    <li><input type="text" className="input" placeholder="Album Name" onChange={handleInputChange} value={albumTitle} disabled />
                                                        {validationErrors.AlbumName && <span className="error">{validationErrors.AlbumName}</span>}
                                                    </li>
                                                    <li><span></span></li>
                                                    <li><div className="note"><p><strong>PLEASE READ:</strong> I affirm that all information provided herein is true and complete and that all album contents indicated as being "licensed" are properly licensed for manufacturing (replication, duplication, and/or printing) under the terms of the license from the intellectual property rights owner(s). I further declare that I am the intellectual property rights owner for all contents indicated as being "owned" by me. I authorize the duplication and replication of all content owned by or licensed by me. I understand that in accordance with the CMC' Anti-Piracy Compliance Program procedures and CMC reserves the right to refuse the processing of any order not complying with the Anti-Piracy Compliance Program guidelines. No order is effective until accepted by CMC and is subject to the Terms and Conditions of Sale.</p>
                                                    <p> </p><p style={{marginTop:"10px"}}><strong>By typing my name</strong> in the box below, <strong>I affirm</strong> that all information provided herein is true and complete and that all album contents indicated as being “licensed” are properly licensed for manufacturing (replication, duplication, and/or printing) under the terms of the license from the intellectual property rights owner(s). I further declare that I am the intellectual property rights owner for all contents indicated
                                                    <strong>as being "owned" by me.</strong> I authorize the duplication and replication of all content owned by or licensed by me. I understand that in accordance with Community Musician’s Anti-Piracy Compliance Program procedures and Community Musician reserves the right to refuse the processing of any order not complying with the Anti-Piracy Compliance Program guidelines. No order is effective until accepted by Community Musician and is subject to the Terms and Conditions of Sale.</p></div>
                                                    </li>
                                                    <li>
                                                    <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="ipR_Agreement"
                                                            name="ipR_Agreement"
                                                            checked={formData.ipR_Agreement}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    ipR_Agreement: e.target.checked,
                                                                })
                                                            }
                                                        />
                                                        <label htmlFor="" className='verifylable'><input type="text" className="input" placeholder="Your Name" />, have read and agree to the terms above.</label>
                                                        
                                                    </li>
                                                    <li> {validationErrors.ipR_Agreement && <span className="error">{validationErrors.ipR_Agreement}</span>}</li>
                                                   
                                                    <li><i><strong>By checking this box</strong> I am certifying that I am authorized to sign this agreement. I affirm that my agreement here constitutes an electronic signature and that this signature meets any and all requirements for an original signature and is legally binding in all respects.</i></li>
                                                    <li className="align-right"><input type="submit" className="btn-submit" value="Submit IPR Form" /></li>
                                                </ul>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default IPRPopupForm;
